<template>
    <v-container fluid class="pt-0 mt-0">
        <HeaderAzul :titulo="curso.disciplina" :subtitulo="curso.curso" :porcento="numero(curso.aula_concluida_total != 0 && curso.aula_total != 0 ? ((curso.aula_concluida_total / curso.aula_total) * 100) : 0, 0)"/>
        <v-row class="justify-center mt-0">
            <v-col cols="12">
                <v-card color="#FFD600" style="border-bottom-left-radius: 25px; border-bottom-right-radius: 25px;">
                    <v-card-title style="color: #005FAB; font-size: 30px;" class="justify-center">Aulas</v-card-title>
                </v-card>
            </v-col>
            <v-col v-for="(a, i) in aulas" cols="12" sm="5" md="6" lg="4" xl="3" :key="i">
                <v-badge style="width: 100%;" color="#005FAB" icon="mdi-check" offset-x="12" offset-y="12">
                    <v-card :to="`/curso/aula/${a.id_aula}`"><v-card-text class="pt-3 my-0"><h2>{{aulas.length-i}} | {{a.datahora_inicio.split(' ')[0].split('-').reverse().join('/')}}</h2></v-card-text></v-card>
                </v-badge>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>

import axios from "axios";
import {mapState} from "vuex";
import HeaderAzul from "@/Views/Estrutura/HeaderAzul";
export default {
    name: "CursoAulas",
    props: ["id_curso", "id_turma"],
    components: {HeaderAzul},
    data: () => ({
        curso : {},
        aulas : [],
    }),
    computed : {
        ...mapState(['baseUrl'])
    },
    methods : {
        aulaListar() {
            return axios.post(`${this.baseUrl}aula/aluno_aula_listar`, {
                id_turma : this.id_turma
            }).then( (res) => {
                this.aulas = res.data.lista
            });
        },
        getCurso() {
            return axios.post(`${this.baseUrl}curso/get`, {id_curso : this.id_curso}).then( (res) => {
                this.curso = res.data
            });
        }
    },
    async activated() {
        await this.getCurso();
        await this.aulaListar();
    }
}
</script>

<style scoped>
    h1 {text-align: left; width: 100%; padding-top:50px;  font-weight: lighter; font-size: 40px; color: #FFFFFF; line-height: 100%;}
    h1 strong {font-weight: bold;}
    h2 {font-size: 19px; font-weight: normal; text-align: center; line-height: 110%; color: #00436F;}
    h2 strong {font-weight: bold;}
    .bg {background-image: url("~@/assets/img/bg-tradutor.png"); background-repeat: no-repeat; background-position-x: -50px; background-position-y: -50px;}
</style>